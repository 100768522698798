import { Response } from './types';

/**
 * 请求错误类
 */
export class RequestError extends Error {
  public ret?: number;
  public msg?: string;
  public response?: Response<unknown>;

  public constructor(ret?: number, msg?: string, response?: Response<unknown>) {
    super(`返回码:${ret},提示信息:${msg}`);
    this.ret = ret;
    this.msg = msg;
    this.response = response;
  }
}

import { AxiosRequestConfig } from 'axios';
import { humpsInteceptors } from './interceptors';
export const DEFAULT_REQUEST_OPTIONS = {
  /**
   * 手动触发请求
   */
  manual: false,
  /**
   * 是否请求错误时重试
   */
  shouldRetryOnError: true,
  /**
   * 错误重试间隔
   */
  errorRetryInterval: 5000,
  /**
   * 最大错误重试次数
   */
  errorRetryCount: 8,
  /**
   * 拦截器
   */
  interceptors: {
    request: [],
    response: humpsInteceptors.response,
  },
};


export const DEFAULT_REQUEST_CONFIG: AxiosRequestConfig = {
  timeout: 10000,
  method: 'get',
  params: {},
};

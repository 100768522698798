import { createStore } from 'vuex';

import { homeChildrenRoutes, HomeRoute } from '@/router/index';
import { filterRouter } from '@/permission';

export default createStore({
  state: {
    userInfo: { userId: 0 },

  },
  getters: {
    userInfo: state => state.userInfo,
    permissionRoutes(state) {
      const { userId } = state.userInfo;
      const childrenRoutes = filterRouter(userId, homeChildrenRoutes);
      HomeRoute.children = childrenRoutes;
      return HomeRoute;
    },
    permissionChildrenRoutes(state) {
      const { userId } = state.userInfo;
      return filterRouter(userId, homeChildrenRoutes);
    },
  },
  mutations: {
    changeUserInfo(state, payload) {
      state.userInfo = payload;
    },
  },
});

import router, { HomeRoute, homeChildrenRoutes } from './router/index';
import { RouteRecordRaw } from 'vue-router';

import store from './store/index';
import { getSelfInfo } from '@/reactivity/login';

export function filterRouter(role: number, routeArr: RouteRecordRaw[]): RouteRecordRaw[] {
  const permissionRouter: RouteRecordRaw[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  routeArr.forEach((item: any) => {
    if (item.meta?.role?.length && item.meta.role.includes(role)) {
      permissionRouter.push(item);
    }
  });
  return permissionRouter;
}

router.beforeEach((to, from, next) => {
  console.log(to.name, 'to.name');

  if (to.name !== 'Login' && to.name !== 'Proxy') {
    const token = sessionStorage.getItem('token');
    console.log(token, 'token');
    if (!token) {
      next({ name: 'Login' });
    } else {
      const { userInfo } = store.getters;
      // 刷新了页面
      if (!userInfo.userId) {
        getSelfInfo().then((res) => {
          const { data } = res;
          console.log(data, 'data');
          if (data?.ret === 0) {
            store.commit('changeUserInfo', data.data);
            const routers = filterRouter(data.data.roleId, homeChildrenRoutes);
            console.log(routers, 'routers');
            HomeRoute.children = routers;
            HomeRoute.redirect = routers[0].path;
            console.log(HomeRoute, 'hoem');
            router.addRoute(HomeRoute);
            console.log(to.path, 'to.path');
            if (routers.some(item => item.path === to.path)) {
              next({ path: to.path, query: to.query });
            } else {
              next({ name: 'Home' });
            }
          } else {
            next({ name: 'Login' });
          }
        });
      } else {
        next();
      }
    }
  } else {
    next();
  }
});


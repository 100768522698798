import { AxiosResponse } from 'axios';
import { camelizeKeys } from 'humps';
import { InterceptorResponse, ResponseResult } from '../types';
import router from '@/router/index';
import Cookies from 'js-cookie';

/**
 * hump 响应拦截器
 */
export const humpsInteceptors: {
  response: InterceptorResponse
} = {
  response: [
    (response: AxiosResponse): AxiosResponse => {
      const { ret } = response.data;
      if (ret === ResponseResult.NotLoggedIn) {
        Cookies.remove('logintype');
        Cookies.remove('qqconnect_access_token');
        sessionStorage.removeItem('token');
        router.push({ name: 'Login' });
      }
      const camelizeData = camelizeKeys(response.data);
      return {
        ...response,
        data: {
          ...camelizeData,
        },
      };
    },
  ],
};

import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

/** 拦截器实现方法 */
export type InterceptorFulfilled<T> = (value: T) => T | Promise<T>;

/** 拦截器错误兜底 */
export type InterceptorRejected = (error: unknown) => unknown;

/** 拦截器管理器 */
export type InterceptorManager<T> = [InterceptorFulfilled<T>, InterceptorRejected];

/** 请求拦截 */
export type InterceptorRequest = Array<InterceptorManager<AxiosRequestConfig>
| InterceptorFulfilled<AxiosRequestConfig>>;

/** 响应拦截 */
export type InterceptorResponse = Array<InterceptorManager<AxiosResponse> | InterceptorFulfilled<AxiosResponse>>;

/** 请求选项 */
export interface RequestOptions<T> {
  /** 手动触发请求 */
  manual?: boolean
  /** 是否请求错误时重试 */
  shouldRetryOnError?: boolean
  /** 错误重试间隔 */
  errorRetryInterval?: number
  /** 最大错误重试次数 */
  errorRetryCount?: number
  /** 是否请求超时重试 */
  shouleRetryOnTimeout?: boolean
  /** 超时重试间隔 */
  timeoutRetryInterval?: number
  /** 最大超时重试次数 */
  timeoutRetryCount?: number
  /** 拦截器 */
  interceptors?: {
    request?: InterceptorRequest
    response?: InterceptorResponse
  }
  /** 请求超时触发回调 */
  onTimeout?: () => void
  /** 请求成功回调 */
  onSuccess?: (response: AxiosResponse<T>, config: AxiosRequestConfig) => void
  /** 请求错误回调 */
  onError?: (error: AxiosError<T>) => void
  /** 请求错误重试回调 */
  onErrorRetry?: (error: AxiosError<T>) => void
}

/** 请求返回码 */
export enum ResponseResult {
  /** 请求成功 */
  Success = 0,
  /** 未登录或者需要重新登录 */
  NotLoggedIn = -1005,
  /** 用户登录成功了，但是没有角色权限 */
  NoRule = -1007

}


/** 请求返回结果 */
export type Response<T, R = ResponseResult> = {
  /** 返回码 */
  ret: R
  /** 返回信息 */
  msg: string
} & T;

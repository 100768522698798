
import { request } from '@/services';
import { Request } from '@/services/requests/request';
import { AxiosRequestConfig } from 'axios';

export interface resultInterface<T> {
  ret?: number;
  msg?: string;
  data: T
}
export interface IGeneralInfo  {
  userId: number;
  loginType: number;
  nickName: string;
  icon: string;
  roleId: number;
}


// B端用户获取自身通用信息

export function getSelfInfo(): Promise<Request<AxiosRequestConfig, resultInterface<IGeneralInfo>>> {
  return request<AxiosRequestConfig, resultInterface<IGeneralInfo>>({
    url: '/api/manage/user/query_self_general',
    method: 'get',
  });
}



import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import App from './App.vue';
import router from './router';
import store from './store';
import 'element-plus/lib/theme-chalk/index.css';
import locale from 'element-plus/lib/locale/lang/zh-cn';
import './permission';
import './element-variables.scss';
createApp(App)
  .use(router)
  .use(store)
  .use(ElementPlus, { locale })
  .mount('#app');

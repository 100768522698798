import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import { defineAsyncComponent } from 'vue';

const Login = defineAsyncComponent(() => import('@/views/Login/index.vue'));

const Home = defineAsyncComponent(() => import('@/views/Home/index.vue'));
const Community = defineAsyncComponent(() => import('@/components/Community/index.vue'));
const Post = defineAsyncComponent(() => import('@/components/Post/index.vue'));
const Reply = defineAsyncComponent(() => import('@/components/Reply/index.vue'));
const Review = defineAsyncComponent(() => import('@/components/Review/index.vue'));
const ReviewPost = defineAsyncComponent(() => import('@/components/ReviewPost/index.vue'));
const ReviewReplay = defineAsyncComponent(() => import('@/components/ReviewReplay/index.vue'));
const User = defineAsyncComponent(() => import('@/components/User/index.vue'));

/**
 * role
 * 0-超级管理员 1-管理员 2-厂商 3-版主 4-写手 5-普通用户
 */


export enum Role {
  manager = 1,
  factory = 2,
  owner = 3,
  writer = 4,
}

const homeChildrenRoutes: Array<RouteRecordRaw> = [
  {
    path: '/home/community',
    name: 'Community',
    component: Community,
    meta: {
      cnName: '社区管理',
      icon: 'el-icon-office-building',
      role: [Role.manager, Role.factory, Role.owner],
    },
  },
  {
    path: '/home/post',
    name: 'Post',
    component: Post,
    meta: {
      cnName: '帖子管理',
      icon: 'el-icon-tickets',
      role: [Role.manager, Role.factory, Role.owner, Role.writer],
    },
  },
  {
    path: '/home/reply',
    name: 'Reply',
    component: Reply,
    meta: {
      cnName: '回复管理',
      icon: 'el-icon-edit-outline',
      role: [Role.manager, Role.factory, Role.owner],
    },
  },
  {
    path: '/home/review',
    name: 'post',
    redirect: '/home/review/post',
    component: Review,
    meta: {
      cnName: '审核管理',
      icon: 'el-icon-coordinate',
      role: [Role.manager, Role.owner],
    },
    children: [
      {
        path: '/home/review/post',
        component: ReviewPost,
        meta: {
          cnName: '帖子审核',
        },
      },
      {
        path: '/home/review/replay',
        component: ReviewReplay,
        meta: {
          cnName: '回复审核',
        },
      },
    ],
  },
  {
    path: '/home/user',
    name: 'User',
    component: User,
    meta: {
      cnName: '用户管理',
      icon: 'el-icon-user',
      role: [Role.manager, Role.owner, Role.writer],
    },
  },
];

const HomeRoute: RouteRecordRaw = {
  path: '/home',
  name: 'Home',
  component: Home,
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export {
  homeChildrenRoutes,
  HomeRoute,
};

export default router;

<template>
    <router-view></router-view>
</template>

<style lang="scss">
@import url('./app.scss');
* {
  margin: 0;
  padding: 0;
}
</style>
